import React, { type ReactElement } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { GADGET_ERROR_TYPE } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { GadgetData } from '@atlassian/jira-dashboard-common/src/types.tsx';
import {
	GadgetErrorFallback,
	GadgetErrorFallbackBase,
} from '@atlassian/jira-dashboard-internal-common/src/common/gadget/gadget-error-fallback/index.tsx';
import { GadgetReadOnlyController } from '@atlassian/jira-dashboard-internal-common/src/controllers/gadget-readonly/index.tsx';
import { GadgetControllerContextConsumer } from '@atlassian/jira-dashboard-internal-common/src/controllers/gadget/context.tsx';
import { GadgetAnalyticsContainer } from '@atlassian/jira-dashboard-internal-common/src/ui/gadget/gadget-analytics-container/index.tsx';
import { GadgetBoundary } from '@atlassian/jira-dashboard-internal-common/src/ui/gadget/gadget-boundary/index.tsx';
import { GadgetInner } from '@atlassian/jira-dashboard-internal-common/src/ui/gadget/gadget-inner/main.tsx';
import type { useConnectGadgetTitles } from '@atlassian/jira-dashboard-internal-common/src/utils/use-gadget-title/index.tsx';

const noop = () => undefined;

type WallboardGadgetProps = {
	gadget: GadgetData;
	dashboardId: string;
	refreshId: number;
	unconfiguredFallback: ReactElement;
	onRegisterConnectGadget: ReturnType<typeof useConnectGadgetTitles>['registerConnectGadget'];
	onUnregisterConnectGadget: ReturnType<typeof useConnectGadgetTitles>['unregisterConnectGadget'];
};

export const WallboardGadget = ({
	gadget,
	dashboardId,
	refreshId,
	unconfiguredFallback,
	onRegisterConnectGadget,
	onUnregisterConnectGadget: onUnRegisterConnectGadget,
}: WallboardGadgetProps) => (
	<GadgetReadOnlyController display="normal" dashboardRefreshId={refreshId} data={gadget}>
		<GadgetControllerContextConsumer>
			{({
				isMaximized,
				shouldUseCache,
				isLoading,
				setIsLoading,
				isUnconfigured,
				setDefaultTitle,
				title,
				onRenameSave,
			}) => (
				<>
					<Header>{title}</Header>
					<GadgetBoundary
						data={gadget}
						isError={!gadget.title}
						errorFallback={
							gadget.errorMessage ? (
								<GadgetErrorFallbackBase id={gadget.id} message={gadget.errorMessage} />
							) : (
								<GadgetErrorFallback
									id={gadget.id}
									errorType={
										!gadget.title
											? GADGET_ERROR_TYPE.NOT_SUPPORTED_ERROR
											: GADGET_ERROR_TYPE.VIEW_ERROR
									}
								/>
							)
						}
					>
						<GadgetAnalyticsContainer data={gadget}>
							<GadgetInner
								dashboardId={dashboardId}
								data={gadget}
								isUnconfigured={isUnconfigured}
								isEditModeEnabled={false}
								isLoading={isLoading}
								isInEditMode={false}
								shouldUseCache={shouldUseCache}
								setIsLoading={setIsLoading}
								setDefaultTitle={setDefaultTitle}
								onEditModeCancel={noop}
								isMaximized={isMaximized}
								unconfiguredFallback={unconfiguredFallback}
								onRegisterConnectGadget={onRegisterConnectGadget}
								onUnregisterConnectGadget={onUnRegisterConnectGadget}
								setTitle={onRenameSave}
							/>
						</GadgetAnalyticsContainer>
					</GadgetBoundary>
				</>
			)}
		</GadgetControllerContextConsumer>
	</GadgetReadOnlyController>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Header = styled.h2({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.DN100),
	marginTop: token('space.200', '16px'),
	marginRight: token('space.200', '16px'),
	marginBottom: token('space.200', '16px'),
	marginLeft: token('space.200', '16px'),
});
